import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import CircleIcon from '@mui/icons-material/Circle'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import DialogActions from '@mui/material/DialogActions'
import Skeleton from '@mui/material/Skeleton'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import IconButton from '@mui/material/IconButton'
import { descending } from 'd3'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import { usePathname } from 'next/navigation'
import { useBreakPoints, useGetTeamByTeamId, useInjuryNews, usePlayer } from '../../lib/hooks'
import { formatDateString } from '../../lib/utils/formatters'
import PlayerHeadshotAvatar from '../boards/PositionalBoard/PlayerHeadshotAvatar'
import Link from '../Link'
import StatsTeamLogo from '../stats/StatsTeamLogo'

type InjuryDialogProps = {
    injury: string | null | undefined
    injuryStartDate: string | null | undefined
    playerName: string | undefined
    playerSlug?: string | null
    playerId?: string | null
    setPlayerId?: React.Dispatch<React.SetStateAction<string | null>>
    injuryId?: string | null
    setSelectedInjuryId?: React.Dispatch<React.SetStateAction<string | null>>
    status?: string | null
    injuryReturnDate?: string | null
}

const NewsSkeleton = (): JSX.Element => (
    <Box sx={{ paddingX: 3 }}>
        <Skeleton sx={{ paddingX: 1 }} width="40%" height={40} />
        <Skeleton sx={{ paddingX: 1 }} width="100%" height={20} />
        <Skeleton sx={{ paddingX: 1 }} width="80%" height={20} />
        <Divider sx={{ paddingTop: 1 }} />
    </Box>
)

const InjuryNewsDialog = ({
    injury,
    injuryStartDate,
    playerName,
    playerSlug,
    playerId,
    setPlayerId,
    injuryId,
    setSelectedInjuryId,
    status,
    injuryReturnDate,
}: InjuryDialogProps): JSX.Element => {
    const { data: injuryNews, isLoading: isInjuryNewsLoading } = useInjuryNews({ playerId, injuryId })
    const { data: player } = usePlayer(playerSlug)
    const { data: team, isLoading: isLoadingTeam } = useGetTeamByTeamId(player?.teamId)
    const { isMobile } = useBreakPoints()
    const pathname = usePathname()
    const handleClose = () => {
        if (setPlayerId) setPlayerId(null)
        if (setSelectedInjuryId) setSelectedInjuryId(null)
    }
    const isInjuryPage = pathname?.includes('injury')
    return (
        <Dialog fullWidth maxWidth="md" open={!!playerId || !!injuryId} onClose={handleClose}>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingY: 1,
                }}
            >
                <Box sx={{ marginLeft: 2, width: { xs: '12%', sm: '7%' }, textAlign: 'center' }}>
                    <PlayerHeadshotAvatar
                        outlined
                        imageUrl={player?.imageUrl}
                        height={isMobile ? 38 : 50}
                        width={isMobile ? 38 : 50}
                    />
                </Box>
                <Box sx={{ width: { xs: '88%', sm: '93%' } }} display="flex" flexDirection="column">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ' space-between',
                            alignItems: isMobile ? 'left' : 'center',
                            paddingRight: isMobile ? 0 : 2,
                            height: '24px',
                            flexDirection: isMobile ? 'column' : 'row',
                        }}
                    >
                        <DialogTitle
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingY: 0,
                                lineHeight: 0,
                                paddingLeft: { xs: 1, sm: 2 },
                                fontSize: { xs: '18px', sm: '20px' },
                            }}
                        >
                            {playerName}{' '}
                            {playerSlug && !isInjuryPage && (
                                <Link href={`/players/${playerSlug}/injury`} target="_blank">
                                    <IconButton color="primary" size="small">
                                        <OpenInNewIcon sx={{ fontSize: '16px' }} />
                                    </IconButton>
                                </Link>
                            )}
                            <Box
                                sx={{
                                    marginRight: '4px',
                                    marginLeft: !isInjuryPage ? undefined : '4px',
                                    fontWeight: 'normal',
                                }}
                            >
                                |
                            </Box>
                            {team?.url || team?.teamAbbreviation ? (
                                <StatsTeamLogo url={team.url} name={team.teamName} placement="right" />
                            ) : (
                                ''
                            )}
                            {isLoadingTeam ? <Skeleton width={20} height={25} /> : ''}
                        </DialogTitle>
                        {status && (
                            <Chip
                                sx={{
                                    width: '65px',
                                    height: '24px',
                                    fontSize: '14px',
                                    fontWeight: 'medium',
                                    backgroundColor: 'white',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    span: {
                                        paddingX: 1,
                                        width: '100%',
                                    },
                                    marginLeft: isMobile ? 1 : 0,
                                }}
                                label={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CircleIcon
                                            sx={{
                                                padding: '0px',
                                                fontSize: '12px',
                                                color:
                                                    status === 'OFS'
                                                        ? 'injury.ofs'
                                                        : status === 'OUT'
                                                        ? 'injury.out30'
                                                        : 'injury.out7',
                                            }}
                                        />
                                        <Box> {status} </Box>
                                    </Box>
                                }
                            />
                        )}
                    </Box>
                    <DialogContent
                        sx={{
                            paddingTop: { xs: 4, sm: 0 },
                            paddingBottom: { xs: 0 },
                            paddingLeft: { xs: 1, sm: 2 },
                        }}
                    >
                        <Box display="flex" width="100%" flexDirection={isMobile ? 'column' : 'row'}>
                            <Box display="flex" gap={1}>
                                {!isMobile && (
                                    <>
                                        <Typography>
                                            {'Injury Date: '}
                                            {formatDateString(injuryStartDate, 'MMM D, YYYY', 'local')}
                                        </Typography>
                                        <Typography component="span">|</Typography>
                                    </>
                                )}
                                <Typography display="flex" alignItems="center">
                                    {injury}
                                    <LocalHospitalIcon
                                        sx={{ color: 'injury.out30', marginLeft: 0.5, fontSize: '18px' }}
                                    />
                                </Typography>
                            </Box>
                            {!isMobile && <Box display="flex" flexGrow={1} />}
                            {injuryReturnDate && (
                                <Typography>
                                    Est. Return: {formatDateString(injuryReturnDate, 'MMM D, YYYY', 'local')}
                                </Typography>
                            )}
                        </Box>
                    </DialogContent>
                </Box>
            </Box>
            <Divider />
            <DialogContent
                sx={{ paddingTop: 0, px: 0, height: injuryNews?.length || isInjuryNewsLoading ? '400px' : '80px' }}
            >
                {isInjuryNewsLoading && (
                    <>
                        <NewsSkeleton />
                        <NewsSkeleton />
                        <NewsSkeleton />
                        <NewsSkeleton />
                        <NewsSkeleton />
                    </>
                )}
                {!injuryNews?.length && !isInjuryNewsLoading && (
                    <Typography
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '22px',
                            color: 'text.secondary',
                            marginTop: 2,
                        }}
                        variant="subtitle1"
                    >
                        No News Found
                    </Typography>
                )}
                {injuryNews && (
                    <List sx={{ px: 0, py: 0 }}>
                        {injuryNews
                            .filter((d) => d.headline)
                            .sort((a, b) => descending(a.publishedAt, b.publishedAt))
                            .map((d) => (
                                <>
                                    <ListItem sx={{ width: '100%', paddingY: 1, paddingX: 3 }}>
                                        <Box sx={{ width: '100%' }}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'baseline',
                                                    flexDirection: { xs: 'column-reverse', sm: 'row' },
                                                }}
                                            >
                                                <Typography variant="subtitle1" fontWeight="medium">
                                                    {d.headline}
                                                </Typography>
                                                <Typography variant="subtitle1" fontSize="14px" fontWeight="medium">
                                                    {formatDateString(d.publishedAt, 'MMM D, YYYY | h:mm A', 'local')}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ marginBottom: 1 }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    {d.notes}
                                                </Typography>
                                            </Box>
                                            <Box lineHeight={1.2}>
                                                <Typography
                                                    component="span"
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: 'medium',
                                                    }}
                                                >
                                                    Analysis:
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    ml={0.5}
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    {d.analysis}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                    </List>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InjuryNewsDialog
